import React from 'react';
import useRedirect from '../../../common/hooks/useRedirect';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import MedwikiLoader from '../../../skeleton/medwiki/medwikiLoader';
import { routeNames } from '../../../router/constants';
import ListingCard from '../../../components/cards/ListingCard/ListingCard';
import { useMedwikiListing } from '../../../Queries/QueryHooks/medwiki.hook';
import { generateProps } from '../../../utils/generateProps';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';

function ResourcesCategoriesList() {
  const { redirectTo } = useRedirect();
  const { data: productGuides, isLoading: productGuidesLoading } = useMedwikiListing(
    false,
    '2',
    '3'
  );
  const { data: patientResources, isLoading: patientResourcesLoading } = useMedwikiListing(
    false,
    '2',
    '4'
  );
  const { contentAccessRedirect } = useContentAccessRedirect();

  const categoriesList = [
    {
      id: 1,
      name: 'Products Guides',
      data: productGuides,
      loading: productGuidesLoading,
      onExploreMoreClick: () =>
        redirectTo(routeNames.dashboard.listing, '', '', `type=2&subtype=3`),
      cardName: 'Product'
    },
    {
      id: 2,
      name: 'Patient Resources',
      data: patientResources,
      loading: patientResourcesLoading,
      onExploreMoreClick: () =>
        redirectTo(routeNames.dashboard.listing, '', '', `type=2&subtype=4`),
      cardName: 'Patient'
    }
  ];

  return (
    <>
      {categoriesList?.map((_category, index) => {
        return (
          <React.Fragment key={index + 1}>
            {_category && (
              <div className="w-100 cmnLandingSlideRow medwikiSliderArea mb-5">
                {!_category?.loading && _category?.data?.length > 0 && (
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h2 className="text-black fs-2 fw-semibold ">{_category?.name}</h2>
                    <a
                      href="javascript:void(0)"
                      className="text-secondary rippleEffect fs-4 fw-medium gtm_cl_medwiki_landing_specialities_explore_more"
                      onClick={() => {
                        _category?.onExploreMoreClick();
                      }}
                    >
                      Explore More
                    </a>
                  </div>
                )}

                {!_category?.loading && _category?.data?.length > 0 ? (
                  <div className="cmnLndngRowSlidePrrnt">
                    <Swiper
                      className="cmnLndngRowSlide medwikiSlide"
                      spaceBetween={24}
                      slidesPerView={3}
                      loop={false}
                      breakpoints={{
                        1: {
                          slidesPerView: 1,
                          spaceBetween: 10
                        },
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 15
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 22
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 24
                        },
                        1400: {
                          slidesPerView: 4,
                          spaceBetween: 26
                        }
                      }}
                    >
                      {_category?.data?.map((item, index) => {
                        return (
                          <SwiperSlide key={index + 1}>
                            <ListingCard {...generateProps(item, contentAccessRedirect)} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                ) : _category?.loading ? (
                  <Swiper
                    className="cmnLndngRowSlide medwikiSlide"
                    spaceBetween={24}
                    slidesPerView={3}
                    loop={false}
                    breakpoints={{
                      1: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 15
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 22
                      },
                      992: {
                        slidesPerView: 3,
                        spaceBetween: 24
                      },
                      1400: {
                        slidesPerView: 4,
                        spaceBetween: 26
                      }
                    }}
                  >
                    {[...Array(4).keys()].map((_video, index) => (
                      <SwiperSlide key={index}>
                        <MedwikiLoader />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : null}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default ResourcesCategoriesList;
